function reload_flt() {
    let parameters = $('.filter-catalog__form').serialize();
    parameters = parameters.replace(/[^&]+=\.?(?:&|$)/g, '');
    if (parameters!=='') dopsign='?'; else dopsign='';
    history.pushState({}, null, window.location.pathname+dopsign+parameters);

    let url = window.location.pathname.replace('categorie','ajax_category')+'?'+parameters;
    url = url.replace('kategoriya','ajax_category');
        
    $.get(url,function(response) {
        let data = JSON.parse(response);

        if(data.redirect) {
            window.location.href = data.redirect + dopsign + parameters;
        }
        
        $('.row-body-main-block-catalog__item').remove();
        $('.row-body-main-block-catalog__content').html(data);
    
        return false;
    }).done( function () {
        $('.main-block-catalog__body').removeAttr('style');
    });
}

function export_file(data, file_type) {
    let d = new Date();

    let month = d.getMonth() + 1;
    let day = d.getDate();

    let output = d.getFullYear() + '-' +
        (('' + month).length < 2 ? '0' : '') + month + '-' +
        (('' + day).length < 2 ? '0' : '') + day;

    let blob = new Blob([data]);
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = "products_" + output + "." + file_type;
    link.click();
}

$(document).ready(function () {

    if ($('.hidden_manufacturer').length <= 0) {
        $('.manufactures-filter-catalog__more').css('display', 'none');
    }

    $('.manufactures-filter-catalog__more').on('click', function () {

        $('.hidden_manufacturer').each(function (index) {
            if (index <= 14) {
                $(this).removeClass('hidden_manufacturer');
            }
        });

        if ($('.hidden_manufacturer').length <= 0) {
            $(this).css('display', 'none');
        }
    });

    $(document).on('click', '.select__option, .checkbox__input, .count-items', function() {

        let brands_values = [];

        $("input[name='brands[]']:checked").each(function () {
            brands_values.push($(this).val());
        });

        $('input[name=sort]').val($('#sort_price').val());
        $('input[name=count-items]').val($('#count-items').val());
        $('input[name=only]').val($('input[name=only]').is(':checked') ? 'yes' : 'no');
        $('input[name=brands]').val(brands_values);

        $('.main-block-catalog__body').css({'opacity':.5});

        reload_flt();
    });

    $(document).on('click', '.csv_export', function () {
        let brands_values = [];

        $("input[name='brands[]']:checked").each(function () {
            brands_values.push($(this).val());
        });

        $.ajax({
            type: "POST",
            url: $(this).attr('data-export-href'),
            data: {
                sort_price: $('#sort_price').val(),
                only: $('input[name=only]').is(':checked') ? 'yes' : 'no',
                brands: brands_values,
                category_id: $('#page_id').val(),
                count_items: $('.count-items').val()
            },
            success: function (data) {
                export_file(data, 'csv');
            }
        });
    });

    $(document).on('click', '.xlsx_export', function () {
        let brands_values = [];

        $("input[name='brands[]']:checked").each(function () {
            brands_values.push($(this).val());
        });

        $.ajax({
            type: "POST",
            url: $(this).attr('data-export-href'),
            data: {
                sort_price: $('#sort_price').val(),
                only: $('input[name=only]').is(':checked') ? 'yes' : 'no',
                brands: brands_values,
                category_id: $('#page_id').val(),
                count_items: $('.count-items').val()
            },
            success: function (data) {
                export_file(data, 'xlsx');
            }
        });
    });

    $(document).on('click', '.info-row-body-main-block-catalog__icon, .copy_picture, .features-info-main-card__copy', function () {
        let copyText = $(this).prev().attr('data-value');
        let textArea = document.createElement("textarea");
        textArea.value = copyText;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        $(this).css('visibility', 'hidden');
        setTimeout(function () {
            $('.info-row-body-main-block-catalog__icon').removeAttr('style');
            $('.copy_picture').removeAttr('style');
            $('.features-info-main-card__copy').removeAttr('style');
        }, 2000);
    });
});